<h1>Заводы</h1>
<mat-table #table [dataSource]="factories">
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Наименование</mat-header-cell>
    <mat-cell *matCellDef="let factory">{{factory.name}}</mat-cell>
  </ng-container>
  <ng-container matColumnDef="address">
    <mat-header-cell *matHeaderCellDef>Адрес</mat-header-cell>
    <mat-cell *matCellDef="let factory"> {{factory.address}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let factory">
      <button mat-mini-fab (click)="editFactory(factory)"><mat-icon>edit</mat-icon></button>
      <button mat-mini-fab (click)="deleteFactory(factory)"><mat-icon>delete</mat-icon></button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
</mat-table>
<mat-action-row>
  <button mat-fab (click)="addFactory()"><mat-icon>add</mat-icon></button>
</mat-action-row>
