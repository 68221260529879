import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { FactoryModel } from 'src/app/models/factory.model';
import { DialogService } from 'src/app/services/dialog.service';
import { FactoryService } from 'src/app/services/factory.service';
import { FactoryEditModalComponent } from '../factory-edit-modal/factory-edit-modal.component';

@Component({
  templateUrl: './factories-view.component.html',
  styleUrls: ['./factories-view.component.scss']
})
export class FactoriesViewComponent implements OnInit {

  factories: FactoryModel[] = []

  tableColumns = ['name', 'address', 'actions'];

  @ViewChild('table') table: MatTable<FactoryModel>;

  constructor(private service: FactoryService, private dialog: MatDialog, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.service.getFactories()
    .subscribe(result => {
      this.factories = result;
    });
  }

  addFactory() {
    let newFactory : FactoryModel = {name: 'Новый завод', address: '', minGarbage: 0, maxGarbage: 0, distance: 0, comment: ''};
    const dialogRef = this.dialog.open(FactoryEditModalComponent, {data: newFactory});
    dialogRef.afterClosed()
    .subscribe(result => {
      if (result != null) {
        this.doAddFactory(result);
      }
    });
  }

  editFactory(factory: FactoryModel) {
    const dialogRef = this.dialog.open(FactoryEditModalComponent, {data: Object.assign({}, factory)});
    dialogRef.afterClosed()
    .subscribe((result: FactoryModel) => {

      if ( result!= null) {
        this.service.update(result)
        .subscribe(updateResult => {
          factory.name = result.name;
          factory.address = result.address;
          factory.comment = result.comment;
          factory.distance = result.distance;
          factory.maxGarbage = result.maxGarbage;
          factory.minGarbage = result.minGarbage;
        });
      }
    });
  }

  deleteFactory(factory: FactoryModel) {

    this.dialogService.yesNoQuestion("Вы уверены. что хотите удалить завод? Все связанные с ним данные будут потеряны.", "Внимание!", true)
    .subscribe(result => {
      if (result) {
        this.service.delete(factory.id)
        .subscribe(deleteResult => {
          const index = this.factories.indexOf(factory, 0);
          delete this.factories[index];
          this.factories.splice(index, 1);
          this.table.renderRows();
        });
      }
    });

  }

  doAddFactory(factory: FactoryModel) {

    this.service.add(factory)
    .subscribe(result => {
      this.factories.push(result);
      this.table.renderRows();
    })
  }

}
