import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PartnersComponent } from './partners/partners.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HeaderComponent } from './navigation/header/header.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule} from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card'
import { MatTreeModule } from '@angular/material/tree';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MatFormFieldControl} from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule, MatSlideToggle } from '@angular/material/slide-toggle'
import { MatTabsModule } from '@angular/material/tabs';
import { SidenavListComponent } from './navigation/sidenav-list/sidenav-list.component'
import { PartnerService } from './services/partner.service';
import { from } from 'rxjs';
import { PartnerCreateComponent } from './partners/create/create.component';
import { PartnerEditComponent } from './partners/edit/edit.component';
import { PricesComponent } from './prices/prices.component';
import { PricesService } from './services/prices.service';
import { SurchargeEditModalComponent } from './prices/modals/surcharge-edit-modal/surcharge-edit-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GoodEditDialogComponent } from './prices/modals/good-edit-dialog/good-edit-dialog.component';
import { GoodService } from './services/good.service';
import { ConfirmDialogComponent } from './modals/common.dialogs/confirm.dialog/confirm.dialog.component';
import { GoodSelectDialogComponent } from './modals/common.dialogs/good-select-dialog/good-select-dialog.component';
import { PartnerGoodPriceEditModalComponent } from './modals/partner-good-price-edit-modal/partner-good-price-edit-modal.component';
import { PartnerOfferSelectDialogComponent } from './prices/modals/partner-offer-select-dialog/partner-offer-select-dialog.component';
import { EditBasePriceManualDialogComponent } from './prices/modals/edit-base-price-manual-dialog/edit-base-price-manual-dialog.component';
import { ENVIRONMENT } from './services/enviroment.service';
import { environment } from 'src/environments/environment';
import { GlobalSettingsService } from './services/global-settings.service';
import { PricesSettingsModalComponent } from './modals/prices-settings-modal/prices-settings-modal.component';
import { DictionariesService } from './services/dictionaries.service';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './services/authentication.service';
import { RestoreComponent } from './login/restore/restore.component';
import { CnangePasswordComponent } from './login/cnange-password/cnange-password.component';
import { UsersComponent } from './admin/users/users.component';
import { UserEditDialogComponent } from './admin/modals/user-edit-dialog/user-edit-dialog.component';
import { PasswordDialogComponent } from './admin/modals/password-dialog/password-dialog.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { ShipmentsComponent } from './shipments/shipments.component';
import { EditShipmentComponent } from './modals/edit-shipment/edit-shipment.component';
import { ShipmentPhotosModalComponent } from './modals/shipment-photos-modal/shipment-photos-modal.component';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { PicturesService } from './services/pictures.service';
import { CurrencyService } from './services/currency.service';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { AppLockService } from './services/app-lock.service';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoaderInterceptor } from './helpers/overlay.interceptor';
import { EditOffersComponent } from './partners/edit-offers/edit-offers.component';
import { JsonInterceptor } from './helpers/json-interceptor';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { PartnerAddressModalComponent } from './modals/partner-address-modal/partner-address-modal.component';
import { DateService } from './services/date.service';
import { ContactsEditorComponent } from './shared-components/contacts-editor/contacts-editor.component';

import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial'
import { AbstractPartnerCardComponent } from './partners/abstract-partner-card/abstract-partner-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge'
import { ContactInputDialogComponent } from './modals/contact-input-dialog/contact-input-dialog.component';
import { PriceListComponent } from './prices/price-list/price-list.component';
import { SafeUrlPipe } from './helpers/safeUrl.pipe';

import { PartnerFactoriesModalComponent } from './modals/partner-factories-modal/partner-factories-modal.component';
import { FactoryService } from './services/factory.service';
import { FactoriesViewComponent } from './factories/factories-view/factories-view.component';
import { FactoriesListComponent } from './shared-components/factories-list/factories-list.component';
import { FactoryEditModalComponent } from './factories/factory-edit-modal/factory-edit-modal.component';
import { OffersEditorComponent } from './shared-components/offers-editor/offers-editor.component';
import { PriceOffersViewComponent } from './shared-components/price-offers-view/price-offers-view.component';
import { NotificationService } from './services/notification.service';
import { NotificationsMenuComponent } from './shared-components/notifications/notifications-menu/notifications-menu.component';
import { NewsViewComponent } from './shared-components/news/news-view/news-view.component';
import { NewsFeedComponent } from './shared-components/news/news-feed/news-feed.component';
import { HomeComponent } from './home/home.component';
import { NewsEditorComponent } from './shared-components/news/news-editor/news-editor.component';
import { AddNewsComponent } from './shared-components/news/add-news/add-news.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NewsService } from './services/news.service';
import { NewsItemPageComponent } from './news/news-item-page/news-item-page.component';
import { SanitizeHtmlPipe } from './helpers/sanitize-html-pipe';
import { PriceCellComponent } from './shared-components/price-table/price-cell/price-cell.component';
import { ProductPricesPageComponent } from './product-prices/product-prices-page/product-prices-page.component';
import { ProductItemViewComponent } from './product-prices/product-item-view/product-item-view.component';
import { ProductListComponent } from './product-prices/product-list/product-list.component';
import { ProductPriceComponent } from './shared-components/product-price/product-price.component';
import { PictureViewComponent } from './shared-components/picture-view/picture-view.component';
import { ProductEditComponent } from './product-prices/product-edit/product-edit.component';
import { ProductComponentsEditComponent } from './product-prices/product-components-edit/product-components-edit.component';
import { EditableValueComponent } from './shared-components/editable-value/editable-value.component';
import { InlineEditorService } from './services/inline-editor.service';
import { ProductsService } from './services/products.service';
import { ProductGroupsComponent } from './product-prices/product-groups/product-groups.component';
import { ProductPicturesEditComponent } from './product-prices/product-pictures-edit/product-pictures-edit.component';
import { ProductPictureItemViewComponent } from './product-prices/product-picture-item-view/product-picture-item-view.component';
import { PictureViewModalComponent } from './modals/picture-view-modal/picture-view-modal.component';
import { PagedBarComponent } from './shared-components/paged-bar/paged-bar.component';
import { NgxPaginationModule } from 'ngx-pagination';

registerLocaleData(localeRu);

@NgModule({
  declarations: [
    AppComponent,
    PartnersComponent,
    NavMenuComponent,
    HeaderComponent,
    SidenavListComponent,
    PartnerCreateComponent,
    PartnerEditComponent,
    PricesComponent,
    SurchargeEditModalComponent,
    GoodEditDialogComponent,
    ConfirmDialogComponent,
    GoodSelectDialogComponent,
    PartnerGoodPriceEditModalComponent,
    PartnerOfferSelectDialogComponent,
    EditBasePriceManualDialogComponent,
    PricesSettingsModalComponent,
    LoginComponent,
    RestoreComponent,
    CnangePasswordComponent,
    UsersComponent,
    UserEditDialogComponent,
    PasswordDialogComponent,
    ShipmentsComponent,
    EditShipmentComponent,
    ShipmentPhotosModalComponent,
    SpinnerOverlayComponent,
    SpinnerComponent,
    EditOffersComponent,
    PartnerAddressModalComponent,
    ContactsEditorComponent,
    AbstractPartnerCardComponent,
    ContactInputDialogComponent,
    PriceListComponent,
    SafeUrlPipe,
    SanitizeHtmlPipe,
    FactoriesListComponent,
    PartnerFactoriesModalComponent,
    FactoriesViewComponent,
    FactoryEditModalComponent,
    OffersEditorComponent,
    PriceOffersViewComponent,
    NotificationsMenuComponent,
    NewsViewComponent,
    NewsFeedComponent,
    HomeComponent,
    NewsEditorComponent,
    AddNewsComponent,
    NewsItemPageComponent,
    PriceCellComponent,
    ProductPricesPageComponent,
    ProductItemViewComponent,
    ProductListComponent,
    ProductPriceComponent,
    PictureViewComponent,
    ProductEditComponent,
    ProductComponentsEditComponent,
    EditableValueComponent,
    ProductGroupsComponent,
    ProductPicturesEditComponent,
    ProductPictureItemViewComponent,
    PictureViewModalComponent,
    PagedBarComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule, MatButtonModule, MatToolbarModule, MatListModule, MatTableModule, MatFormFieldModule, MatInputModule,
    MatCardModule, MatDatepickerModule, MatNativeDateModule, MatDialogModule, MatCheckboxModule, MatExpansionModule, MatTreeModule,
    MatSnackBarModule, MatSelectModule, MatMenuModule, MatSliderModule, MatSlideToggleModule, MatTabsModule, MatCarouselModule.forRoot(),
    DragDropModule, MatTooltipModule, MatBadgeModule, NgxPaginationModule,
    EcoFabSpeedDialModule, AngularEditorModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' })
  ],
  providers: [PartnerService, PricesService, GoodService,
    { provide: ENVIRONMENT, useValue: environment },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JsonInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "ru" },
    GlobalSettingsService, DictionariesService, AuthenticationService, PicturesService, CurrencyService, AppLockService, DateService,
    FactoryService, NotificationService, NewsService, InlineEditorService, ProductsService],
  bootstrap: [AppComponent],
  entryComponents: [ SpinnerOverlayComponent, ContactInputDialogComponent, PartnerGoodPriceEditModalComponent, GoodSelectDialogComponent,
   GoodEditDialogComponent, PartnerOfferSelectDialogComponent, SurchargeEditModalComponent, ShipmentPhotosModalComponent, EditBasePriceManualDialogComponent,
   EditShipmentComponent, ConfirmDialogComponent, UserEditDialogComponent, PartnerFactoriesModalComponent, FactoriesViewComponent, PricesSettingsModalComponent,
   FactoryEditModalComponent, EditShipmentComponent, UserEditDialogComponent, PasswordDialogComponent, ProductItemViewComponent, ProductListComponent, ProductPriceComponent,
   PictureViewComponent, ProductEditComponent, ProductComponentsEditComponent, EditableValueComponent, ProductGroupsComponent, ProductPicturesEditComponent, ProductPictureItemViewComponent,
   PictureViewModalComponent, PagedBarComponent
  ]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl(
        // Note: This isn't really the right approach to
        // import MDI's icons, but we have to for StackBlitz
        // to retrieve the icons.
        'https://unpkg.com/@mdi/angular-material/mdi.svg'
      )
    );
  }
 }
